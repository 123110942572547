import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
    Container,
    Paper,
    TextField,
    Button,
    Typography,
    Box,
    Alert
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { auth } from '../services/api';

function Login() {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [error, setError] = useState('');
    const { login, user } = useAuth();
    const navigate = useNavigate();

    if (user) {
        return <Navigate to="/" replace />;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await auth.login(credentials);
            login(response.data.token, response.data.user);
            navigate('/');
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to login');
        }
    };

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/BackgroundImage.jpeg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                position: 'relative'
            }}
        >
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/images/RPOLOGO.jpg`}
                        alt="Logo"
                        sx={{
                            width: '350px',
                            height: 'auto',
                            mb: 4,
                            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
                        }}
                    />
                    <Paper 
                        elevation={3} 
                        sx={{ 
                            p: 4, 
                            width: '100%',
                            backgroundColor: 'rgba(45, 60, 105, 0.8)',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
                            transition: 'all 0.3s ease',
                            color: 'white',
                            '& .MuiTextField-root': {
                                '& .MuiOutlinedInput-root': {
                                    color: 'white',
                                    fontSize: '1.1rem',
                                    '& fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.3)',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: 'rgba(255, 255, 255, 0.5)',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'white',
                                    },
                                    '& input': {
                                        padding: '14px 16px',
                                    }
                                },
                                '& .MuiInputLabel-root': {
                                    color: 'rgba(255, 255, 255, 0.9)',
                                    backgroundColor: 'rgba(45, 60, 105, 0.7)',
                                    padding: '0 4px',
                                    fontSize: '1.1rem',
                                    '&.Mui-focused': {
                                        color: 'white'
                                    },
                                    '&.MuiFormLabel-filled': {
                                        color: 'rgba(255, 255, 255, 0.9)'
                                    }
                                }
                            },
                            '&:hover': {
                                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)',
                                backgroundColor: 'rgba(45, 60, 105, 0.85)',
                            }
                        }}
                    >
                        <Typography 
                            component="h1" 
                            variant="h5" 
                            align="center" 
                            gutterBottom 
                            sx={{ fontFamily: "'Orbitron', sans-serif", fontWeight: 700 }}
                        >
                            Machine Shop <span style={{ color: '#F15A22' }}>RPO</span>
                        </Typography>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        <form onSubmit={handleSubmit}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={credentials.username}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                value={credentials.password}
                                onChange={handleChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        </form>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
}

export default Login;