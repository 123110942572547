import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Alert
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { announcements } from '../../services/api';
import { filterProfanity } from '../../utils/profanityFilter';

const validationSchema = yup.object({
    title: yup.string().required('Title is required'),
    body: yup.string().required('Content is required')
});

function AddEditAnnouncementModal({ open, onClose, announcement }) {
    const queryClient = useQueryClient();
    const isEdit = Boolean(announcement);

    const mutation = useMutation(
        (values) => {
            const filteredValues = {
                title: filterProfanity(values.title.trim()),
                body: filterProfanity(values.body.trim())
            };
            return isEdit
                ? announcements.update(announcement.id, filteredValues)
                : announcements.create(filteredValues);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('announcements');
                onClose();
            }
        }
    );

    const formik = useFormik({
        initialValues: {
            title: announcement?.title || '',
            body: announcement?.body || ''
        },
        validationSchema,
        onSubmit: (values) => {
            mutation.mutate(values);
        },
        enableReinitialize: true
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                    {isEdit ? 'Edit Announcement' : 'New Announcement'}
                </DialogTitle>
                <DialogContent>
                    {mutation.error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            Error saving announcement
                        </Alert>
                    )}
                    <TextField
                        fullWidth
                        margin="normal"
                        name="title"
                        label="Title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        name="body"
                        label="Content"
                        multiline
                        rows={4}
                        value={formik.values.body}
                        onChange={formik.handleChange}
                        error={formik.touched.body && Boolean(formik.errors.body)}
                        helperText={formik.touched.body && formik.errors.body}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="submit" variant="contained" disabled={mutation.isLoading}>
                        {isEdit ? 'Save Changes' : 'Post Announcement'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default AddEditAnnouncementModal;