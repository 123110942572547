import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';

// Context
import { AuthProvider } from './contexts/AuthContext';

// Components
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';

// Pages
import Login from './pages/Login';
import Inventory from './pages/Inventory';
import Barrels from './pages/Barrels';
import Announcements from './pages/Announcements';
import Dashboard from './pages/Dashboard';

const theme = createTheme({
    palette: {
        primary: {
            main: '#F15A22',
            light: '#FF7F4D',
            dark: '#D14A1A',
        },
        secondary: {
            main: '#E64A19',
            light: '#FF7043',
            dark: '#BF360C',
        },
    },
});

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                    <Router>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Navigate to="/dashboard" replace />} />
                                <Route
                                    path="dashboard"
                                    element={
                                        <PrivateRoute>
                                            <Dashboard />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="inventory"
                                    element={
                                        <PrivateRoute>
                                            <Inventory />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="barrels"
                                    element={
                                        <PrivateRoute>
                                            <Barrels />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="announcements"
                                    element={
                                        <PrivateRoute>
                                            <Announcements />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                        </Routes>
                    </Router>
                </AuthProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
