import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
    Box,
    Grid,
    Typography,
    Card,
    CardContent,
    CircularProgress,
    Alert,
} from '@mui/material';
import {
    Warning as WarningIcon,
    Announcement as AnnouncementIcon,
} from '@mui/icons-material';
import { inventory, barrels, announcements } from '../services/api';
import PendingOrders from '../components/dashboard/PendingOrders';
import StockAlerts from '../components/inventory/StockAlerts';
import OrderDialog from '../components/inventory/OrderDialog';

function StatCard({ title, value, icon, color, isLoading }) {
    return (
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {icon}
                    <Typography variant="h6" sx={{ ml: 1 }}>
                        {title}
                    </Typography>
                </Box>
                {isLoading ? (
                    <CircularProgress size={24} />
                ) : (
                    <Typography variant="h4" color={color}>
                        {value}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
}

function AnnouncementCard({ announcement }) {
    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ color: 'primary.main' }}>
                        {announcement.title}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        {new Date(announcement.created_at).toLocaleTimeString()}
                    </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                    Posted by {announcement.author}
                </Typography>
            </CardContent>
        </Card>
    );
}

function Dashboard() {
    const [orderDialogOpen, setOrderDialogOpen] = useState(false);
    const [itemsToOrder, setItemsToOrder] = useState([]);

    const handleOrderClick = (items) => {
        setItemsToOrder(items);
        setOrderDialogOpen(true);
    };

    const { 
        data: inventoryItems = [], 
        isLoading: inventoryLoading,
        error: inventoryError 
    } = useQuery('inventory', inventory.getAll, {
        refetchInterval: 5000 // Refresh every 5 seconds
    });

    const { 
        data: barrelData = [], 
        isLoading: barrelsLoading,
        error: barrelsError 
    } = useQuery('barrels', barrels.getAll, {
        refetchInterval: 5000 // Refresh every 5 seconds
    });

    const { 
        data: todayAnnouncements = [], 
        isLoading: announcementsLoading 
    } = useQuery('today-announcements', () => 
        announcements.getAll({ 
            date: new Date().toISOString().split('T')[0] 
        }).then(res => res.data)
    );

    const lowStockItems = Array.isArray(inventoryItems) 
        ? inventoryItems.filter(item => item.quantity <= item.threshold)
        : [];

    const almostDepletedItems = Array.isArray(inventoryItems)
        ? inventoryItems.filter(item => 
            item.quantity > item.threshold && 
            item.quantity <= (item.threshold + 5)
        )
        : [];

    const lowStockBarrels = Array.isArray(barrelData)
        ? barrelData.filter(barrel => {
            if (barrel.threshold_operator === '>=') {
                return barrel.quantity >= barrel.threshold;
            }
            return barrel.quantity <= barrel.threshold;
        })
        : [];

    const isLoading = inventoryLoading || barrelsLoading || announcementsLoading;
    const hasError = inventoryError || barrelsError || announcementsLoading;

    if (hasError) {
        return (
            <Alert severity="error" sx={{ mt: 2 }}>
                Error loading dashboard data
            </Alert>
        );
    }

    return (
        <Box>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Dashboard
            </Typography>

            <StockAlerts
                lowStockItems={lowStockItems}
                almostDepletedItems={almostDepletedItems}
                lowStockBarrels={lowStockBarrels}
                onOrderClick={handleOrderClick}
            />

            <OrderDialog
                open={orderDialogOpen}
                onClose={() => {
                    setOrderDialogOpen(false);
                    setItemsToOrder([]);
                }}
                items={itemsToOrder}
            />

            {/* Pending Orders */}
            <PendingOrders />

            {/* Rest of the dashboard content */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} md={6}>
                    <StatCard
                        title="Low Stock Alerts"
                        value={lowStockItems.length + lowStockBarrels.length}
                        icon={<WarningIcon sx={{ color: 'warning.main' }} />}
                        color="warning.main"
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StatCard
                        title="Recent Announcements"
                        value={Array.isArray(todayAnnouncements) ? todayAnnouncements.length : 0}
                        icon={<AnnouncementIcon color="secondary" />}
                        color="secondary"
                        isLoading={announcementsLoading}
                    />
                </Grid>
            </Grid>

            {/* Today's Announcements */}
            {todayAnnouncements.length > 0 && (
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AnnouncementIcon color="primary" />
                        Today's Announcements
                    </Typography>
                    {todayAnnouncements.map(announcement => (
                        <AnnouncementCard 
                            key={announcement.id} 
                            announcement={announcement} 
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
}

export default Dashboard; 