import React, { useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Button,
    Alert
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon
} from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { barrels } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import BarrelIcon from '../components/barrels/BarrelIcon';
import BarrelDialog from '../components/barrels/BarrelDialog';
import DeleteBarrelDialog from '../components/barrels/DeleteBarrelDialog';

const defaultBarrels = [
    { type: 'chips', color: '#000000', label: 'Chips', capacity: 5, threshold: 2 },
    { type: 'vacuum', color: '#8B4513', label: 'Vacuum', capacity: 5, threshold: 2 },
    { type: 'coolant', color: '#40E0D0', label: 'Coolant', capacity: 5, threshold: 2 },
    { type: 'oil', color: '#FFA500', label: 'Oil', capacity: 5, threshold: 2 }
];

function BarrelSection({ barrel, onToggle, onEdit, onDelete, isAdmin }) {
    const warningBuffer = Math.max(1, Math.floor(barrel.capacity * 0.2));
    
    const isAlertState = barrel.threshold_operator === '>=' 
        ? barrel.quantity >= barrel.threshold  // Alert when full
        : barrel.quantity <= barrel.threshold; // Alert when empty

    const isWarningState = barrel.threshold_operator === '>=' 
        ? (barrel.quantity < barrel.threshold && barrel.quantity >= (barrel.threshold - warningBuffer))
        : (barrel.quantity > barrel.threshold && barrel.quantity <= (barrel.threshold + warningBuffer));

    const getAlertMessage = () => {
        if (barrel.threshold_operator === '>=') {
            return '(Needs Emptying)'; // For chips - alert when full
        }
        return '(Needs Filling)'; // For coolant - alert when empty
    };

    return (
        <Box sx={{ width: '100%', mb: 4 }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 2,
                borderBottom: theme => isAlertState 
                    ? `2px solid ${theme.palette.error.main}`
                    : isWarningState 
                        ? `2px solid ${theme.palette.warning.main}`
                        : `1px solid ${theme.palette.divider}`,
                pb: 1
            }}>
                <Typography 
                    variant="h6" 
                    color={isAlertState ? 'error' : isWarningState ? 'warning.main' : 'inherit'}
                >
                    {barrel.label}
                    {isAlertState && (
                        <Typography component="span" color="error" sx={{ ml: 1, fontSize: '0.8em' }}>
                            {getAlertMessage()}
                        </Typography>
                    )}
                </Typography>
                {isAdmin && (
                    <Box>
                        <IconButton onClick={() => onEdit(barrel)} size="small">
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => onDelete(barrel)} size="small" color="error">
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )}
            </Box>
            <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 2, 
                justifyContent: 'flex-start',
                pl: 1
            }}>
                {Array.from({ length: barrel.capacity }).map((_, index) => (
                    <BarrelIcon
                        key={`${barrel.barrel_type}-${index}`}
                        filled={index < barrel.quantity}
                        color={barrel.color}
                        onClick={() => onToggle(barrel.barrel_type, index)}
                        disabled={false}
                        width={100}
                        height={60}
                    />
                ))}
            </Box>
        </Box>
    );
}

function Barrels() {
    const { isAdmin } = useAuth();
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedBarrel, setSelectedBarrel] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [barrelToDelete, setBarrelToDelete] = useState(null);

    const { 
        data: barrelData = [],
        isLoading: barrelsLoading,
        error: barrelsError 
    } = useQuery('barrels', async () => {
        try {
            const response = await barrels.getAll();
            console.log('Raw barrel response:', response);
            
            // Ensure we always return an array
            if (!response) {
                console.warn('No response from barrels API');
                return [];
            }
            
            // If response is already an array, return it
            if (Array.isArray(response)) {
                return response;
            }
            
            // If response has a data property that's an array, return that
            if (response.data && Array.isArray(response.data)) {
                return response.data;
            }
            
            // If we get here, we don't have a valid response
            console.warn('Invalid barrel response format:', response);
            return [];
        } catch (error) {
            console.error('Error fetching barrels:', error);
            throw error;
        }
    }, {
        retry: 1,
        refetchOnWindowFocus: false
    });

    const updateMutation = useMutation(
        (data) => {
            console.log('=== Start Update Mutation ===');
            console.log('Mutation input data:', data);
            
            if ('quantity' in data) {
                // Quantity update
                console.log('Performing quantity update');
                return barrels.updateQuantity(data.barrel_type, data.quantity);
            } else {
                // Full barrel update
                console.log('Performing full update');
                console.log('Update data:', data);
                return barrels.update(data.barrel_type, data);
            }
        },
        {
            onSuccess: (response) => {
                console.log('Update successful:', response);
                queryClient.invalidateQueries('barrels');
                setDialogOpen(false);
            },
            onError: (error) => {
                console.error('=== Update Error ===');
                console.error('Error details:', error);
                console.error('Response data:', error.response?.data);
                console.error('Status:', error.response?.status);
                alert(`Failed to update barrel: ${error.response?.data?.message || error.message}`);
            }
        }
    );

    const addBarrelMutation = useMutation(
        (newBarrel) => {
            console.log('=== Adding New Barrel ===');
            console.log('Mutation payload:', newBarrel);
            return barrels.create(newBarrel);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('barrels');
                setDialogOpen(false);
            },
            onError: (error) => {
                console.error('Error creating barrel:', error);
                console.error('Error response:', error.response?.data);
                if (error.response?.data?.existing) {
                    const existing = error.response.data.existing;
                    alert(`Cannot create barrel: A barrel with type "${existing.barrel_type}" (label: ${existing.label}) already exists`);
                    console.log('Conflicting barrel details:', existing);
                } else {
                    alert(`Failed to create barrel: ${error.response?.data?.message || 'Unknown error'}`);
                }
            }
        }
    );

    const deleteBarrelMutation = useMutation(
        (barrel_type) => barrels.delete(barrel_type),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('barrels');
                setDeleteDialogOpen(false);
                setBarrelToDelete(null);
            },
            onError: (error) => {
                console.error('Error deleting barrel:', error);
                alert('Error deleting barrel. Please try again.');
            }
        }
    );

    const handleToggle = (type, index) => {
        const barrel = barrelData.find(b => b.barrel_type === type);
        if (!barrel) return;

        let newQuantity;
        if (index < barrel.quantity) {
            newQuantity = index;
        } else {
            newQuantity = index + 1;
        }

        console.log('Toggling barrel:', {
            barrel_type: type,
            index,
            currentQuantity: barrel.quantity,
            newQuantity
        });

        updateMutation.mutate({ 
            barrel_type: type, 
            quantity: Math.max(0, Math.min(newQuantity, barrel.capacity)) 
        });
    };

    const handleEdit = (barrel) => {
        setSelectedBarrel(barrel);
        setDialogOpen(true);
    };

    const handleDelete = (barrel) => {
        console.log('Deleting barrel:', barrel);
        setBarrelToDelete(barrel);
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        if (barrelToDelete?.barrel_type) {
            console.log('Confirming delete for:', barrelToDelete.barrel_type);
            deleteBarrelMutation.mutate(barrelToDelete.barrel_type);
        }
    };

    const handleAddBarrel = () => {
        setSelectedBarrel(null);
        setDialogOpen(true);
    };

    const handleSaveBarrel = (barrelData) => {
        console.log('=== Start Save Barrel ===');
        console.log('Input barrelData:', barrelData);
        
        if (selectedBarrel) {
            // For editing existing barrel
            updateMutation.mutate({
                barrel_type: selectedBarrel.barrel_type,
                label: barrelData.label,
                color: barrelData.color,
                capacity: parseInt(barrelData.capacity),
                threshold: parseInt(barrelData.threshold),
                threshold_operator: barrelData.threshold_operator
            });
        } else {
            // For creating new barrel
            const barrel_type = barrelData.label.toLowerCase().replace(/\s+/g, '-');
            addBarrelMutation.mutate({
                barrel_type,
                label: barrelData.label,
                color: barrelData.color,
                capacity: parseInt(barrelData.capacity),
                threshold: parseInt(barrelData.threshold),
                threshold_operator: barrelData.threshold_operator
            });
        }
        console.log('=== End Save Barrel ===');
    };

    if (barrelsError) {
        return (
            <Alert severity="error" sx={{ mt: 2 }}>
                Error loading barrel data: {barrelsError.message}
            </Alert>
        );
    }

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 4 
            }}>
                <Typography variant="h4">Barrels</Typography>
                {isAdmin() && (
                    <Button
                        variant="contained"
                        onClick={handleAddBarrel}
                        startIcon={<AddIcon />}
                    >
                        Add Barrel
                    </Button>
                )}
            </Box>
            
            {barrelData.map((barrel) => (
                <BarrelSection
                    key={barrel.barrel_type}
                    barrel={barrel}
                    onToggle={handleToggle}
                    onEdit={isAdmin() ? handleEdit : undefined}
                    onDelete={isAdmin() ? handleDelete : undefined}
                    isAdmin={isAdmin()}
                />
            ))}

            {isAdmin && dialogOpen && (
                <BarrelDialog
                    open={dialogOpen}
                    onClose={() => {
                        setDialogOpen(false);
                        setSelectedBarrel(null);
                    }}
                    onSave={handleSaveBarrel}
                    barrel={selectedBarrel}
                />
            )}

            {isAdmin && deleteDialogOpen && (
                <DeleteBarrelDialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    onConfirm={handleConfirmDelete}
                    barrelLabel={barrelToDelete?.label}
                />
            )}
        </Box>
    );
}

export default Barrels;