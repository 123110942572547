import React from 'react';
import { Alert, Button, Box } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useQuery } from 'react-query';
import { orders } from '../../services/api';

function StockAlerts({ lowStockItems, almostDepletedItems, lowStockBarrels, onOrderClick }) {
    const { isAdmin } = useAuth();
    
    const { data: pendingOrders = [] } = useQuery(
        'pending-orders',
        orders.getPending,
        { 
            refetchInterval: 5000,
            select: (data) => Array.isArray(data) ? data : []
        }
    );

    // Get IDs of items that are already ordered
    const pendingOrderItemIds = pendingOrders.map(order => order.item_id);
    
    // Filter out items that are already ordered
    const orderableItems = lowStockItems.filter(item => !pendingOrderItemIds.includes(item.id));

    return (
        <Box sx={{ mb: 3 }}>
            {lowStockItems.length > 0 && (
                <Alert 
                    severity="error" 
                    sx={{ mb: 1 }}
                    action={
                        isAdmin() && orderableItems.length > 0 && (
                            <Button
                                color="inherit"
                                size="small"
                                onClick={() => onOrderClick(orderableItems)}
                            >
                                Place Orders
                            </Button>
                        )
                    }
                >
                    Low Stock Items: {lowStockItems.map(item => item.name).join(', ')}
                </Alert>
            )}
            {almostDepletedItems.length > 0 && (
                <Alert severity="warning" sx={{ mb: 1 }}>
                    Almost Depleted Items: {almostDepletedItems.map(item => item.name).join(', ')}
                </Alert>
            )}
            {lowStockBarrels.length > 0 && (
                <Alert severity="warning" sx={{ mb: 1 }}>
                    {lowStockBarrels.map(barrel => {
                        if (barrel.threshold_operator === '>=') {
                            return `${barrel.label} barrels are filling up`;
                        }
                        return `Low Stock: ${barrel.label}`;
                    }).join(', ')}
                </Alert>
            )}
        </Box>
    );
}

export default StockAlerts; 