import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

function BarrelIcon({ filled, color, onClick, disabled }) {
    return (
        <Tooltip title={disabled ? "Cannot modify" : (filled ? "Click to empty" : "Click to fill")}>
            <IconButton 
                onClick={onClick} 
                disabled={disabled}
                sx={{ 
                    padding: '4px',
                    '&:hover': {
                        transform: disabled ? 'none' : 'scale(1.1)'
                    }
                }}
            >
                <svg 
                    width="50"
                    height="80"
                    viewBox="0 0 80 110"
                >
                    <g>
                        {/* Top cap - widened more */}
                        <path
                            d="M10 12C10 11 11 10 12 10H68C69 10 70 11 70 12V15C70 16 69 17 68 17H12C11 17 10 16 10 15V12Z"
                            fill={color}
                        />
                        {/* Bottom cap - widened more */}
                        <path
                            d="M10 95C10 94 11 93 12 93H68C69 93 70 94 70 95V98C70 99 69 100 68 100H12C11 100 10 99 10 98V95Z"
                            fill={color}
                        />
                        {/* Main body - widened and taller */}
                        <path
                            d="M12 15C10 15 8 17 8 19V91C8 93 10 95 12 95H68C70 95 72 93 72 91V19C72 17 70 15 68 15H12Z"
                            fill={filled ? color : 'none'}
                            stroke={color}
                            strokeWidth="1.5"
                        />
                        {/* Vertical lines - adjusted for new height */}
                        <path
                            d="M12 17L12 93M68 17L68 93"
                            stroke={color}
                            strokeWidth="0.5"
                        />
                    </g>
                </svg>
            </IconButton>
        </Tooltip>
    );
}

export default BarrelIcon; 