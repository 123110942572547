import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box
} from '@mui/material';
import { ChromePicker } from 'react-color';

function BarrelDialog({ open, onClose, onSave, barrel = null }) {
    const [values, setValues] = useState({
        label: barrel?.label || '',
        color: barrel?.color || '#000000',
        capacity: barrel?.capacity || 5,
        threshold: barrel?.threshold || 2,
        threshold_operator: barrel?.threshold_operator || '<='
    });
    const [error, setError] = useState('');
    const [showColorPicker, setShowColorPicker] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('=== Dialog Submit ===');
        console.log('Form values:', values);
        console.log('Existing barrel:', barrel);
        
        if (!values.label) {
            setError('Barrel type is required');
            return;
        }

        const normalizedType = values.label.toLowerCase().trim().replace(/\s+/g, '-');
        console.log('Normalized barrel type:', normalizedType);

        const submitData = {
            label: values.label.trim(),
            color: values.color,
            capacity: parseInt(values.capacity),
            threshold: parseInt(values.threshold),
            threshold_operator: values.threshold_operator,
            // For new barrels only
            ...((!barrel) && {
                type: normalizedType,
                barrel_type: normalizedType
            })
        };

        console.log('Submit data:', submitData);
        onSave(submitData);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {barrel ? 'Edit Barrel Type' : 'Add New Barrel Type'}
                </DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Barrel Type"
                        value={values.label}
                        onChange={(e) => setValues({ ...values, label: e.target.value })}
                    />
                    <Box sx={{ mb: 2 }}>
                        <Button
                            onClick={() => setShowColorPicker(!showColorPicker)}
                            variant="outlined"
                            sx={{ backgroundColor: values.color }}
                        >
                            {showColorPicker ? 'Close Color Picker' : 'Choose Color'}
                        </Button>
                        {showColorPicker && (
                            <Box sx={{ position: 'absolute', zIndex: 2 }}>
                                <ChromePicker
                                    color={values.color}
                                    onChange={(color) => setValues({ ...values, color: color.hex })}
                                />
                            </Box>
                        )}
                    </Box>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Threshold Operator</InputLabel>
                        <Select
                            value={values.threshold_operator}
                            onChange={(e) => setValues({ ...values, threshold_operator: e.target.value })}
                            label="Threshold Operator"
                        >
                            <MenuItem value="<=">Less than or equal to (Alert when low)</MenuItem>
                            <MenuItem value=">=">Greater than or equal to (Alert when high)</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Capacity"
                        type="number"
                        value={values.capacity}
                        onChange={(e) => setValues({ ...values, capacity: parseInt(e.target.value) })}
                        inputProps={{ min: 1, max: 10 }}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Alert Threshold"
                        type="number"
                        value={values.threshold}
                        onChange={(e) => setValues({ ...values, threshold: parseInt(e.target.value) })}
                        inputProps={{ min: 1, max: values.capacity }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="submit" variant="contained">
                        {barrel ? 'Save Changes' : 'Add Barrel Type'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default BarrelDialog; 