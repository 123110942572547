import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
    Box,
    Paper,
    Typography,
    Button,
    IconButton,
    Alert,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    AddCircleOutline as IncrementIcon,
    RemoveCircleOutline as DecrementIcon
} from '@mui/icons-material';
import { inventory } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import OrderDialog from '../components/inventory/OrderDialog';
import StockAlerts from '../components/inventory/StockAlerts';

function InventoryDialog({ open, onClose, item = null }) {
    const queryClient = useQueryClient();
    const isEdit = Boolean(item);
    const [apiError, setApiError] = useState('');

    const initialValues = {
        name: item?.name || '',
        quantity: item?.quantity || 0,
        description: item?.description || '',
        threshold: item?.threshold || 10
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    React.useEffect(() => {
        if (item) {
            setValues({
                name: item.name || '',
                quantity: item.quantity || 0,
                description: item.description || '',
                threshold: item?.threshold || 10
            });
        } else {
            setValues(initialValues);
        }
        setErrors({});
        setApiError('');
    }, [item]);

    const mutation = useMutation(
        (data) => isEdit 
            ? inventory.update(item.id, data)
            : inventory.create(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('inventory');
                onClose();
            },
            onError: (error) => {
                console.error('Mutation error:', error);
                setApiError(error.response?.data?.message || 'Failed to save item');
            }
        }
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prev => ({
            ...prev,
            [name]: name.includes('quantity') || name.includes('threshold') 
                ? parseInt(value, 10) || 0 
                : value
        }));
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: undefined }));
        }
    };

    const validate = () => {
        const newErrors = {};
        if (!values.name.trim()) newErrors.name = 'Name is required';
        if (values.quantity < 0) newErrors.quantity = 'Quantity must be positive';
        if (values.threshold < 0) newErrors.threshold = 'Threshold must be positive';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate();
        if (Object.keys(newErrors).length === 0) {
            const submitData = {
                name: values.name.trim(),
                quantity: Number(values.quantity),
                threshold: Number(values.threshold),
                description: values.description.trim() || null
            };
            console.log('Submitting data:', JSON.stringify(submitData));
            mutation.mutate(submitData);
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>
                    {isEdit ? 'Edit Item' : 'Add New Item'}
                </DialogTitle>
                <DialogContent>
                    {apiError && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {apiError}
                        </Alert>
                    )}
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Name"
                                value={values.name}
                                onChange={handleChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="quantity"
                                label="Quantity"
                                type="number"
                                value={values.quantity}
                                onChange={handleChange}
                                error={Boolean(errors.quantity)}
                                helperText={errors.quantity}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="threshold"
                                label="Low Stock Threshold"
                                type="number"
                                value={values.threshold}
                                onChange={handleChange}
                                error={Boolean(errors.threshold)}
                                helperText={errors.threshold}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="description"
                                label="Description (Optional)"
                                multiline
                                rows={3}
                                value={values.description}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        disabled={mutation.isLoading}
                    >
                        {mutation.isLoading 
                            ? 'Saving...' 
                            : isEdit ? 'Save Changes' : 'Add Item'
                        }
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

function Inventory() {
    const { isAdmin } = useAuth();
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [sortModel, setSortModel] = useState([{ field: 'name', sort: 'asc' }]);
    const [orderDialogOpen, setOrderDialogOpen] = useState(false);
    const [itemsToOrder, setItemsToOrder] = useState([]);

    const { data: items = [], isLoading, error } = useQuery(
        ['inventory', sortModel],
        async () => {
            const field = sortModel[0]?.field || 'name';
            const sort = sortModel[0]?.sort || 'asc';
            const response = await inventory.getAll(field, sort);
            console.log('Inventory response:', response);
            return Array.isArray(response) ? response : [];
        }
    );

    const updateQuantityMutation = useMutation(
        ({ id, quantity }) => {
            console.log('Updating quantity:', { id, quantity });
            return inventory.updateQuantity(id, quantity);
        },
        {
            onSuccess: () => {
                console.log('Quantity update successful');
                queryClient.invalidateQueries('inventory');
            },
            onError: (error) => {
                console.error('Quantity update error:', error.response?.data || error);
                alert('Failed to update quantity');
            }
        }
    );

    const handleQuantityChange = (e, id, currentQuantity, change) => {
        e.stopPropagation();
        console.log('Quantity change params:', { id, currentQuantity, change });
        const newQuantity = Math.max(0, currentQuantity + change);
        console.log('New quantity:', newQuantity);
        
        updateQuantityMutation.mutate({ 
            id, 
            quantity: newQuantity 
        });
    };

    const baseColumns = [
        { 
            field: 'name', 
            headerName: 'Name', 
            flex: 1,
            sortable: true 
        },
        { 
            field: 'description', 
            headerName: 'Description', 
            flex: 2,
            sortable: true 
        },
        { 
            field: 'quantity', 
            headerName: 'Quantity', 
            width: 200,
            sortable: true,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton
                        size="small"
                        onClick={(e) => handleQuantityChange(e, params.row.id, params.row.quantity, -1)}
                        disabled={params.row.quantity <= 0}
                    >
                        <DecrementIcon />
                    </IconButton>
                    <Typography>{params.row.quantity}</Typography>
                    <IconButton
                        size="small"
                        onClick={(e) => handleQuantityChange(e, params.row.id, params.row.quantity, 1)}
                    >
                        <IncrementIcon />
                    </IconButton>
                </Box>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            sortable: true,
            renderCell: (params) => {
                const status = getStockStatus(params.row.quantity, params.row.threshold);
                return (
                    <Typography sx={{ color: status.color }}>
                        {status.label}
                    </Typography>
                );
            }
        }
    ];

    const adminColumns = [
        ...baseColumns,
        { 
            field: 'threshold', 
            headerName: 'Threshold', 
            width: 130,
            sortable: true 
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            renderCell: (params) => (
                <Box>
                    <IconButton 
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(params.row);
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton 
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(params.row);
                        }}
                        color="error"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    const columns = isAdmin() ? adminColumns : baseColumns;

    const handleEdit = (item) => {
        setSelectedItem(item);
        setDialogOpen(true);
    };

    const handleDelete = (item) => {
        console.log('Delete item:', item);
        if (!item?.id) {
            console.error('No item ID found');
            return;
        }
        setItemToDelete(item);
        setDeleteDialogOpen(true);
    };

    const getStockStatus = (quantity, threshold) => {
        const warningBuffer = Math.ceil(threshold * 0.5);
        
        if (quantity <= threshold) {
            return { label: 'Low Stock', color: 'error.main' };
        } else if (quantity <= (threshold + warningBuffer)) {
            return { label: 'Getting Low', color: 'warning.main' };
        } else {
            return { label: 'Sufficient Stock', color: 'success.main' };
        }
    };

    const getLowStockItems = () => {
        return items.filter(item => item.quantity <= item.threshold);
    };

    const getAlmostDepletedItems = () => {
        return items.filter(item => {
            const warningBuffer = Math.ceil(item.threshold * 0.5);
            return item.quantity > item.threshold && 
                   item.quantity <= (item.threshold + warningBuffer);
        });
    };

    const lowStockItems = getLowStockItems();
    const almostDepletedItems = getAlmostDepletedItems();

    const deleteMutation = useMutation(
        (id) => {
            console.log('Deleting inventory item with ID:', id);
            return inventory.delete(id.toString());
        },
        {
            onSuccess: () => {
                console.log('Delete successful');
                queryClient.invalidateQueries('inventory');
                setDeleteDialogOpen(false);
                setItemToDelete(null);
            },
            onError: (error) => {
                console.error('Delete failed:', error.response?.data || error);
                alert('Failed to delete item');
            }
        }
    );

    const handleConfirmDelete = () => {
        console.log('Confirming delete for item:', itemToDelete);
        if (itemToDelete?.id) {
            deleteMutation.mutate(itemToDelete.id);
        }
    };

    const handleOrderClick = (items) => {
        setItemsToOrder(items);
        setOrderDialogOpen(true);
    };

    if (error) {
        return <Alert severity="error">Error loading inventory</Alert>;
    }

    return (
        <Box>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4">Inventory</Typography>
                {isAdmin() && (
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setSelectedItem(null);
                            setDialogOpen(true);
                        }}
                    >
                        Add Item
                    </Button>
                )}
            </Box>

            <StockAlerts
                lowStockItems={lowStockItems}
                almostDepletedItems={almostDepletedItems}
                lowStockBarrels={[]}
                onOrderClick={handleOrderClick}
            />

            <Paper sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={items}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    loading={isLoading}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                        setSortModel(model);
                    }}
                />
            </Paper>

            <InventoryDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setSelectedItem(null);
                }}
                item={selectedItem}
            />

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete {itemToDelete?.name}?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} color="error">Delete</Button>
                </DialogActions>
            </Dialog>

            <OrderDialog
                open={orderDialogOpen}
                onClose={() => {
                    setOrderDialogOpen(false);
                    setItemsToOrder([]);
                }}
                items={itemsToOrder}
            />
        </Box>
    );
}

export default Inventory;