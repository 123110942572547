import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production' 
    ? '/api'  // This will be proxied through nginx
    : 'http://localhost:5000/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add request interceptor to add auth token
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add response interceptor for error handling
api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('API Error:', error.response?.data || error.message);
        return Promise.reject(error);
    }
);

export const auth = {
    login: (credentials) => api.post('/auth/login', credentials),
    register: (userData) => api.post('/auth/register', userData),
};

export const inventory = {
    getAll: (sortField = 'name', sortOrder = 'asc') => 
        api.get(`/inventory?sort=${sortField}&order=${sortOrder}`).then(res => res.data || []),
    create: (data) => api.post('/inventory', data).then(res => res.data),
    update: (id, data) => api.put(`/inventory/${id}`, data).then(res => res.data),
    updateQuantity: (id, quantity) => api.patch(`/inventory/${id}/quantity`, { quantity }).then(res => res.data),
    delete: (id) => api.delete(`/inventory/${id}`).then(res => res.data),
    getLowStock: () => api.get('/inventory/low-stock').then(res => res.data || [])
};

export const barrels = {
    getAll: () => api.get('/barrels').then(res => res.data),
    create: (data) => {
        console.log('API create barrel request:', data);
        return api.post('/barrels', data)
            .then(res => res.data)
            .catch(error => {
                console.log('API create error:', error.response?.data);
                throw error;
            });
    },
    update: (barrel_type, data) => api.put(`/barrels/${barrel_type}/update`, data).then(res => res.data),
    delete: (barrel_type) => api.delete(`/barrels/${barrel_type}`).then(res => res.data),
    updateQuantity: (barrel_type, quantity) => api.put(`/barrels/${barrel_type}`, { quantity }).then(res => res.data)
};

export const announcements = {
    getAll: () => api.get('/announcements').then(res => ({ data: res.data })),
    create: (data) => api.post('/announcements', data).then(res => ({ data: res.data })),
    update: (id, data) => api.put(`/announcements/${id}`, data).then(res => ({ data: res.data })),
    delete: (id) => api.delete(`/announcements/${id}`).then(res => ({ data: res.data })),
    addReply: (announcementId, content) => 
        api.post(`/announcements/${announcementId}/replies`, { content }).then(res => ({ data: res.data })),
    deleteReply: (announcementId, replyId) => 
        api.delete(`/announcements/${announcementId}/replies/${replyId}`).then(res => ({ data: res.data })),
    updateReply: (announcementId, replyId, content) => 
        api.put(`/announcements/${announcementId}/replies/${replyId}`, { content }).then(res => ({ data: res.data }))
};

export const orders = {
    getPending: () => api.get('/orders/pending').then(res => res.data || []),
    create: (data) => api.post('/orders', data).then(res => res.data),
    receive: (id) => api.put(`/orders/${id}/receive`).then(res => res.data)
};

export default api;