import React, { useState } from 'react';
import {
    Box, Typography, TextField, Button, IconButton, Divider,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useMutation, useQueryClient } from 'react-query';
import { announcements } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { filterProfanity } from '../../utils/profanityFilter';

function EditReplyDialog({ open, onClose, reply, onSave }) {
    const [content, setContent] = useState(reply?.content || '');

    const handleSubmit = (e) => {
        e.preventDefault();
        const filteredContent = filterProfanity(content.trim());
        onSave(filteredContent);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Edit Reply</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="submit" variant="contained">
                        Save Changes
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

function ReplySection({ announcementId, replies }) {
    const { user, isAdmin } = useAuth();
    const queryClient = useQueryClient();
    const [replyContent, setReplyContent] = useState('');
    const [editingReply, setEditingReply] = useState(null);

    const addReplyMutation = useMutation(
        (content) => announcements.addReply(announcementId, content),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('announcements');
                setReplyContent('');
            }
        }
    );

    const editReplyMutation = useMutation(
        ({ replyId, content }) => announcements.updateReply(announcementId, replyId, content),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('announcements');
                setEditingReply(null);
            }
        }
    );

    const deleteReplyMutation = useMutation(
        (replyId) => announcements.deleteReply(announcementId, replyId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('announcements');
            }
        }
    );

    const handleSubmitReply = (e) => {
        e.preventDefault();
        if (replyContent.trim()) {
            const filteredContent = filterProfanity(replyContent.trim());
            addReplyMutation.mutate(filteredContent);
        }
    };

    const handleEditSave = (content) => {
        if (editingReply && content.trim()) {
            editReplyMutation.mutate({
                replyId: editingReply.id,
                content: content
            });
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle2" gutterBottom>
                Replies ({replies.length})
            </Typography>
            
            {replies.map((reply) => (
                <Box key={reply.id} sx={{ ml: 2, mb: 1, display: 'flex', alignItems: 'flex-start' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                            {reply.username} • {new Date(reply.created_at).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                            {reply.content}
                        </Typography>
                    </Box>
                    {isAdmin() && (
                        <Box>
                            <IconButton
                                size="small"
                                onClick={() => setEditingReply(reply)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                color="error"
                                onClick={() => deleteReplyMutation.mutate(reply.id)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            ))}

            <Box component="form" onSubmit={handleSubmitReply} sx={{ mt: 2 }}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Write a reply..."
                    value={replyContent}
                    onChange={(e) => setReplyContent(e.target.value)}
                    sx={{ mb: 1 }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    disabled={!replyContent.trim() || addReplyMutation.isLoading}
                >
                    Post Reply
                </Button>
            </Box>

            <EditReplyDialog
                open={Boolean(editingReply)}
                onClose={() => setEditingReply(null)}
                reply={editingReply}
                onSave={handleEditSave}
            />
        </Box>
    );
}

export default ReplySection; 