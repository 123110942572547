import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { 
    AppBar, 
    Toolbar, 
    Typography, 
    Button, 
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    useTheme,
    useMediaQuery
} from '@mui/material';
import {
    Inventory as InventoryIcon,
    LocalDrink as BarrelsIcon,
    Announcement as AnnouncementIcon,
    Menu as MenuIcon,
    Logout as LogoutIcon,
    Dashboard as DashboardIcon,
    ChevronLeft as ChevronLeftIcon
} from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';

const drawerWidth = 240;

function Layout() {
    const { logout, isAdmin: isAdminFn } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(!isMobile);

    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
        { text: 'Inventory', icon: <InventoryIcon />, path: '/inventory' },
        { text: 'Barrels', icon: <BarrelsIcon />, path: '/barrels' },
        { text: 'Announcements', icon: <AnnouncementIcon />, path: '/announcements' }
    ];

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const drawer = (
        <div>
            <Toolbar sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'flex-end',
                px: [1]
            }}>
                <IconButton onClick={handleDrawerToggle}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <List>
                {menuItems.map((item) => (
                    <ListItem 
                        key={item.text} 
                        onClick={() => {
                            navigate(item.path);
                            if (isMobile) setDrawerOpen(false);
                        }}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                cursor: 'pointer'
                            }
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar 
                position="fixed" 
                sx={{ 
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    width: '100%',
                    backgroundColor: '#fff',
                    color: '#000'
                }}
            >
                <Toolbar sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    maxWidth: 'calc(100% - 48px)',
                    margin: '0 auto',
                    width: '100%'
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="toggle drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography 
                        variant="h6" 
                        component="div" 
                        sx={{ 
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontWeight: 700,
                            fontFamily: "'Orbitron', sans-serif"
                        }}
                    >
                        Machine Shop <span style={{ color: '#F15A22' }}>RPO</span>
                    </Typography>
                    <Button 
                        color="inherit" 
                        onClick={handleLogout}
                        startIcon={<LogoutIcon />}
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>

            <Drawer
                variant={isMobile ? "temporary" : "persistent"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                {drawer}
            </Drawer>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 2,
                    width: '100%',
                    maxWidth: drawerOpen && !isMobile ? 'calc(85% - 240px)' : '85%',
                    boxSizing: 'border-box',
                    ml: drawerOpen && !isMobile ? `${drawerWidth}px` : 0,
                    mt: { xs: '64px', sm: '64px' },
                    mx: 'auto',
                    transform: 'translateX(-120px)',
                    transition: theme.transitions.create(['margin', 'width', 'transform', 'max-width'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
}

export default Layout;