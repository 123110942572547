// Basic list of words to filter - expand this list as needed
const profanityList = [
    'badword1',
    'badword2',
    'fuck',
    'shit',
    'ass',
    'bitch',
    'damn',
    'crap',
    'piss',
    'dick',
    'cock',
    'pussy',
    'asshole',
    'bastard',
    'slut',
    'whore',
    "faggot",
    "anal",
"anus",
"arse",
"ass",
"balls",
"ballsack",
"bastard",
"biatch",
"bitch",
"bloody",
"blow job",
"blowjob",
"bollock",
"bollok",
"boner",
"boob",
"bugger",
"bum",
"butt",
"buttplug",
"clitoris",
"cock",
"coon",
"crap",
"cunt",
"damn",
"dick",
"dildo",
"dyke",
"f u c k",
"fag",
"feck",
"felching",
"fellate",
"fellatio",
"flange",
"fuck",
"fudge packer",
"fudgepacker",
"God damn",
"Goddamn",
"hell",
"homo",
"jerk",
"jizz",
"knob end",
"knobend",
"labia",
"lmao",
"lmfao",
"muff",
"nigga",
"nigger",
"omg",
"penis",
"piss",
"poop",
"prick",
"pube",
"pussy",
"queer",
"s hit",
"scrotum",
"sex",
"sh1t",
"shit",
"slut",
"smegma",
"spunk",
"tit",
"tosser",
"turd",
"twat",
"vagina",
"wank",
"whore",
"wtf",
"سكس",
"طيز",
"شرج",
"لعق",
"لحس",
"مص",
"تمص",
"بيضان",
"ثدي",
"بز",
"بزاز",
"حلمة",
"مفلقسة",
"بظر",
"كس",
"فرج",
"شهوة",
"شاذ",
"مبادل",
"عاهرة",
"جماع",
"قضيب",
"زب",
"لوطي",
"لواط",
"سحاق",
"سحاقية",
"اغتصاب",
"خنثي",
"احتلام",
"نيك",
"متناك",
"متناكة",
"شرموطة",
"عرص",
"خول",
"قحبة",
"لبوة",
"bordel",
"buzna",
"čumět",
"čurák",
"debil",
"do piče",
"do prdele",
"dršťka",
"držka",
"flundra",
"hajzl",
"hovno",
"chcanky",
"chuj",
"jebat",
"kokot",
"kokotina",
"koňomrd",
"kunda",
"kurva",
"mamrd",
"mrdat",
"mrdka",
"mrdník",
"oslošoust",
"piča",
"píčus",
"píchat",
"pizda",
"prcat",
"prdel",
"prdelka",
"sračka",
"srát",
"šoustat",
"šulin",
"vypíčenec",
"zkurvit",
"zkurvysyn",
"zmrd",
"žrát",
"bøsserøv",
"fisse",
"fissehår",
"hestepik",
"kussekryller",
"lort",
"luder",
"pik",
"pikhår",
"pikslugeri",
"piksutteri",
"pis",
"røv",
"røvhul",
"røvskæg",
"røvspræke",
"analritter",
"arsch",
"arschficker",
"arschlecker",
"arschloch",
"bimbo",
"bratze",
"bumsen",
"bonze",
"dödel",
"fick",
"ficken",
"flittchen",
"fotze",
"fratze",
"hackfresse",
"hure",
"hurensohn",
"ische",
"kackbratze",
"kacke",
"kacken",
"kackwurst",
"kampflesbe",
"kanake",
"kimme",
"lümmel",
"MILF",
"möpse",
"morgenlatte",
"möse",
"mufti",
"muschi",
"nackt",
"neger",
"nippel",
"nutte",
"onanieren",
"orgasmus",
"pimmel",
"pimpern",
"pinkeln",
"pissen",
"pisser",
"popel",
"poppen",
"porno",
"reudig",
"rosette",
"schabracke",
"schlampe",
"scheiße",
"scheisser",
"schiesser",
"schnackeln",
"schwanzlutscher",
"schwuchtel",
"tittchen",
"titten",
"vögeln",
"vollpfosten",
"wichse",
"wichsen",
"wichser",
"bugren",
"bugri",
"bugru",
"ĉiesulino",
"ĉiesulo",
"diofek",
"diofeka",
"fek",
"feken",
"fekfikanto",
"feklekulo",
"fekulo",
"fik",
"fikado",
"fikema",
"fikfek",
"fiki",
"fikiĝi",
"fikiĝu",
"fikilo",
"fikklaŭno",
"fikota",
"fiku",
"forfiki",
"forfikiĝu",
"forfiku",
"forfurzu",
"forpisi",
"forpisu",
"furzulo",
"kacen",
"kaco",
"kacsuĉulo",
"kojono",
"piĉen",
"piĉo",
"zamenfek",
"Asesinato",
"asno",
"bastardo",
"Bollera",
"Cabron",
"Caca",
"Chupada",
"Chupapollas",
"Chupetón",
"concha",
"Concha de tu madre",
"Coño",
"Coprofagía",
"Culo",
"Drogas",
"Esperma",
"Fiesta de salchichas",
"Follador",
"Follar",
"Gilipichis",
"Gilipollas",
"Hacer una paja",
"Haciendo el amor",
"Heroína",
"Hija de puta",
"Hijaputa",
"Hijo de puta",
"Hijoputa",
"Idiota",
"Imbécil",
"infierno",
"Jilipollas",
"Kapullo",
"Lameculos",
"Maciza",
"Macizorra",
"maldito",
"Mamada",
"Marica",
"Maricón",
"Mariconazo",
"martillo",
"Mierda",
"Nazi",
"Orina",
"Pedo",
"Pervertido",
"Pezón",
"Pinche",
"Prostituta",
"Puta",
"Racista",
"Ramera",
"Sádico",
"Semen",
"Sexo",
"Sexo oral",
"Soplagaitas",
"Soplapollas",
"Tetas grandes",
"Tía buena",
"Travesti",
"Trio",
"Verga",
"vete a la mierda",
"Vulva",
"آب کیر",
"ارگاسم",
"برهنه",
"پورن",
"پورنو",
"تجاوز",
"تخمی",
"جق",
"جقی",
"جلق",
"جنده",
"چوچول",
"حشر",
"حشری",
"داف",
"دودول",
"ساک زدن",
"سکس",
"سکس کردن",
"سکسی",
"سوپر",
"شق کردن",
"شهوت",
"شهوتی",
"شونبول",
"فیلم سوپر",
"کس",
"کس دادن",
"کس کردن",
"کسکش",
"کوس",
"کون",
"کون دادن",
"کون کردن",
"کونکش",
"کونی",
"کیر",
"کیری",
"لاپا",
"لاپایی",
"لاشی",
"لخت",
"لش",
"منی",
"هرزه",
"alfred nussi",
"bylsiä",
"haahka",
"haista paska",
"haista vittu",
"hatullinen",
"helvetisti",
"hevonkuusi",
"hevonpaska",
"hevonperse",
"hevonvittu",
"hevonvitunperse",
"hitosti",
"hitto",
"huorata",
"hässiä",
"juosten kustu",
"jutku",
"jutsku",
"jätkä",
"kananpaska",
"koiranpaska",
"kuin esterin perseestä",
"kulli",
"kullinluikaus",
"kuppainen",
"kusaista",
"kuseksia",
"kusettaa",
"kusi",
"kusipää",
"kusta",
"kyrpiintynyt",
"kyrpiintyä",
"kyrpiä",
"kyrpä",
"kyrpänaama",
"kyrvitys",
"lahtari",
"lutka",
"molo",
"molopää",
"mulkero",
"mulkku",
"mulkvisti",
"muna",
"munapää",
"munaton",
"mutakuono",
"mutiainen",
"naida",
"nainti",
"narttu",
"neekeri",
"nekru",
"nuolla persettä",
"nussia",
"nussija",
"nussinta",
"paljaalla",
"palli",
"pallit",
"paneskella",
"panettaa",
"panna",
"pano",
"pantava",
"paska",
"paskainen",
"paskamainen",
"paskanmarjat",
"paskantaa",
"paskapuhe",
"paskapää",
"paskattaa",
"paskiainen",
"paskoa",
"pehko",
"pentele",
"perkele",
"perkeleesti",
"persaukinen",
"perse",
"perseennuolija",
"perseet olalla",
"persereikä",
"perseääliö",
"persläpi",
"perspano",
"persvako",
"pilkunnussija",
"pillu",
"pillut",
"pipari",
"piru",
"pistää",
"pyllyvako",
"reikä",
"reva",
"ripsipiirakka",
"runkata",
"runkkari",
"runkkaus",
"runkku",
"ryssä",
"rättipää",
"saatanasti",
"suklaaosasto",
"tavara",
"toosa",
"tuhkaluukku",
"tumputtaa",
"turpasauna",
"tussu",
"tussukka",
"tussut",
"vakipano",
"vetää käteen",
"viiksi",
"vittu",
"vittuilla",
"vittuilu",
"vittumainen",
"vittuuntua",
"vittuuntunut",
"vitun",
"vitusti",
"vituttaa",
"vitutus",
"äpärä",
"baiser",
"bander",
"bigornette",
"bite",
"bitte",
"bloblos",
"bosser",
"bourré",
"bourrée",
"brackmard",
"branlage",
"branler",
"branlette",
"branleur",
"branleuse",
"brouter le cresson",
"cailler",
"chatte",
"chiasse",
"chier",
"chiottes",
"clito",
"con",
"connard",
"connasse",
"conne",
"couilles",
"cramouille",
"cul",
"déconne",
"déconner",
"drague",
"emmerdant",
"emmerder",
"emmerdeur",
"emmerdeuse",
"enculé",
"enculée",
"enculeur",
"enculeurs",
"enfoiré",
"enfoirée",
"étron",
"fille de pute",
"fils de pute",
"folle",
"foutre",
"gerbe",
"gerber",
"gouine",
"grande folle",
"grogniasse",
"gueule",
"jouir",
"la putain de ta mère",
"MALPT",
"ménage à trois",
"merde",
"merdeuse",
"merdeux",
"meuf",
"nègre",
"nique ta mère",
"nique ta race",
"palucher",
"pédale",
"pédé",
"péter",
"pipi",
"pouffiasse",
"pousse-crotte",
"putain",
"pute",
"ramoner",
"sac à merde",
"salaud",
"salope",
"suce",
"tapette",
"teuf",
"tringler",
"trique",
"trou du cul",
"turlute",
"veuve",
"zigounette",
"zizi",
"aand",
"aandu",
"balatkar",
"beti chod",
"bhadva",
"bhadve",
"bhandve",
"bhootni ke",
"bhosad",
"bhosadi ke",
"boobe",
"chakke",
"chinaal",
"chinki",
"chod",
"chodu",
"chodu bhagat",
"chooche",
"choochi",
"choot",
"choot ke baal",
"chootia",
"chootiya",
"chuche",
"chuchi",
"chudai khanaa",
"chudan chudai",
"chut",
"chut ke baal",
"chut ke dhakkan",
"chut maarli",
"chutad",
"chutadd",
"chutan",
"chutia",
"chutiya",
"gaand",
"gaandfat",
"gaandmasti",
"gaandufad",
"gandu",
"gashti",
"gasti",
"ghassa",
"ghasti",
"harami",
"haramzade",
"hawas",
"hawas ke pujari",
"hijda",
"hijra",
"jhant",
"jhant chaatu",
"jhant ke baal",
"jhantu",
"kamine",
"kaminey",
"kanjar",
"kutta",
"kutta kamina",
"kutte ki aulad",
"kutte ki jat",
"kuttiya",
"loda",
"lodu",
"lund",
"lund choos",
"lund khajoor",
"lundtopi",
"lundure",
"maa ki chut",
"maal",
"madar chod",
"mooh mein le",
"mutth",
"najayaz",
"najayaz aulaad",
"najayaz paidaish",
"paki",
"pataka",
"patakha",
"raand",
"randi",
"saala",
"saala kutta",
"saali kutti",
"saali randi",
"suar",
"suar ki aulad",
"tatte",
"tatti",
"teri maa ka bhosada",
"teri maa ka boba chusu",
"teri maa ki chut",
"tharak",
"tharki",
"balfasz",
"balfaszok",
"balfaszokat",
"balfaszt",
"barmok",
"barmokat",
"barmot",
"barom",
"baszik",
"bazmeg",
"buksza",
"bukszák",
"bukszákat",
"bukszát",
"búr",
"búrok",
"csöcs",
"csöcsök",
"csöcsöket",
"csöcsöt",
"fasz",
"faszfej",
"faszfejek",
"faszfejeket",
"faszfejet",
"faszok",
"faszokat",
"faszt",
"fing",
"fingok",
"fingokat",
"fingot",
"franc",
"francok",
"francokat",
"francot",
"geci",
"gecibb",
"gecik",
"geciket",
"gecit",
"kibaszott",
"kibaszottabb",
"kúr",
"kurafi",
"kurafik",
"kurafikat",
"kurafit",
"kurvák",
"kurvákat",
"kurvát",
"leggecibb",
"legkibaszottabb",
"legszarabb",
"marha",
"marhák",
"marhákat",
"marhát",
"megdöglik",
"pele",
"pelék",
"picsa",
"picsákat",
"picsát",
"pina",
"pinák",
"pinákat",
"pinát",
"pofa",
"pofákat",
"pofát",
"pöcs",
"pöcsök",
"pöcsöket",
"pöcsöt",
"punci",
"puncik",
"segg",
"seggek",
"seggeket",
"segget",
"seggfej",
"seggfejek",
"seggfejeket",
"seggfejet",
"szajha",
"szajhák",
"szajhákat",
"szajhát",
"szar",
"szarabb",
"szarik",
"szarok",
"szarokat",
"szart",
"allupato",
"ammucchiata",
"anale",
"arrapato",
"arrusa",
"arruso",
"assatanato",
"bagascia",
"bagassa",
"bagnarsi",
"baldracca",
"balle",
"battere",
"battona",
"belino",
"biga",
"bocchinara",
"bocchino",
"bofilo",
"boiata",
"bordello",
"brinca",
"bucaiolo",
"budiùlo",
"buona donna",
"busone",
"cacca",
"caccati in mano e prenditi a schiaffi",
"caciocappella",
"cadavere",
"cagare",
"cagata",
"cagna",
"cammello",
"cappella",
"carciofo",
"carità",
"casci",
"cazzata",
"cazzimma",
"cazzo",
"checca",
"chiappa",
"chiavare",
"chiavata",
"ciospo",
"ciucciami il cazzo",
"coglione",
"coglioni",
"cornuto",
"cozza",
"culattina",
"culattone",
"di merda",
"ditalino",
"duro",
"fare unaŠ",
"fava",
"femminuccia",
"fica",
"figa",
"figlio di buona donna",
"figlio di puttana",
"figone",
"finocchio",
"fottere",
"fottersi",
"fracicone",
"fregna",
"frocio",
"froscio",
"fuori come un balcone",
"goldone",
"grilletto",
"guanto",
"guardone",
"incazzarsi",
"incoglionirsi",
"ingoio",
"l'arte bolognese",
"leccaculo",
"lecchino",
"lofare",
"loffa",
"loffare",
"lumaca",
"manico",
"mannaggia",
"merda",
"merdata",
"merdoso",
"mignotta",
"minchia",
"minchione",
"mona",
"monta",
"montare",
"mussa",
"nave scuola",
"nerchia",
"nudo",
"padulo",
"palle",
"palloso",
"patacca",
"patonza",
"pecorina",
"pesce",
"picio",
"pincare",
"pipa",
"pippone",
"pirla",
"pisciare",
"piscio",
"pisello",
"pistola",
"pistolotto",
"pomiciare",
"pompa",
"pompino",
"porca",
"porca madonna",
"porca miseria",
"porca puttana",
"porco due",
"porco zio",
"potta",
"puppami",
"puttana",
"quaglia",
"recchione",
"regina",
"rincoglionire",
"rizzarsi",
"rompiballe",
"ruffiano",
"sbattere",
"sbattersi",
"sborra",
"sborrata",
"sborrone",
"sbrodolata",
"scopare",
"scopata",
"scorreggiare",
"sega",
"slinguare",
"slinguata",
"smandrappata",
"soccia",
"socmel",
"sorca",
"spagnola",
"spompinare",
"sticchio",
"stronza",
"stronzata",
"stronzo",
"succhiami",
"sveltina",
"sverginare",
"tarzanello",
"terrone",
"testa di cazzo",
"tette",
"tirare",
"topa",
"troia",
"trombare",
"uccello",
"vacca",
"vaffanculo",
"vangare",
"venire",
"zinne",
"zio cantante",
"zoccola",
"3p",
"g スポット",
"s ＆ m",
"sm",
"sm女王",
"xx",
"アジアのかわいい女の子",
"アスホール",
"アナリングス",
"アナル",
"いたずら",
"イラマチオ",
"ウェブカメラ",
"エクスタシー",
"エスコート",
"エッチ",
"エロティズム",
"エロティック",
"オーガズム",
"オカマ",
"おしっこ",
"おしり",
"オシリ",
"おしりのあな",
"おっぱい",
"オッパイ",
"オナニー",
"オマンコ",
"おもらし",
"お尻",
"カーマスートラ",
"カント",
"クリトリス",
"グループ・セックス",
"グロ",
"クンニリングス",
"ゲイ・セックス",
"ゲイの男性",
"ゲイボーイ",
"ゴールデンシャワー",
"コカイン",
"ゴックン",
"サディズム",
"しばり",
"スウィンガー",
"スカートの中",
"スカトロ",
"ストラップオン",
"ストリップ劇場",
"スラット",
"スリット",
"セクシーな",
"セクシーな 10 代",
"セックス",
"ソドミー",
"ちんこ",
"ディープ・スロート",
"ディック",
"ディルド",
"デートレイプ",
"デブ",
"テレフォンセックス",
"ドッグスタイル",
"トップレス",
"なめ",
"ニガー",
"ヌード",
"ネオ・ナチ",
"ハードコア",
"パイパン",
"バイブレーター",
"バック・スタイル",
"パンティー",
"ビッチ",
"ファック",
"ファンタジー",
"フィスト",
"フェティッシュ",
"フェラチオ",
"ふたなり",
"ぶっかけ",
"フック",
"プリンス アルバート ピアス",
"プレイボーイ",
"ベアバック",
"ペニス",
"ペニスバンド",
"ボーイズラブ",
"ボールギャグ",
"ボールを蹴る",
"ぽっちゃり",
"ホモ",
"ポルノ",
"ポルノグラフィー",
"ボンテージ",
"マザー・ファッカー",
"マスターベーション",
"まんこ",
"やおい",
"やりまん",
"ユダヤ人",
"ラティーナ",
"ラバー",
"ランジェリー",
"レイプ",
"レズビアン",
"ローター",
"ロリータ",
"淫乱",
"陰毛",
"革抑制",
"騎上位",
"巨根",
"巨乳",
"強姦犯",
"玉なめ",
"玉舐め",
"緊縛",
"近親相姦",
"嫌い",
"後背位",
"合意の性交",
"拷問",
"殺し方",
"殺人事件",
"殺人方法",
"支配",
"児童性虐待",
"自己愛性",
"射精",
"手コキ",
"獣姦",
"女の子",
"女王様",
"女子高生",
"女装",
"新しいポルノ",
"人妻",
"人種",
"性交",
"正常位",
"生殖器",
"精液",
"挿入",
"足フェチ",
"足を広げる",
"大陰唇",
"脱衣",
"茶色のシャワー",
"中出し",
"潮吹き女",
"潮吹き男性",
"直腸",
"剃毛",
"貞操帯",
"奴隷",
"二穴",
"乳首",
"尿道プレイ",
"覗き",
"売春婦",
"縛り",
"噴出",
"糞",
"糞尿愛好症",
"糞便",
"平手打ち",
"変態",
"勃起する",
"夢精",
"毛深い",
"誘惑",
"幼児",
"幼児性愛者",
"裸",
"裸の女性",
"乱交",
"両性",
"両性具有",
"両刀",
"輪姦",
"卍",
"宦官",
"肛門",
"膣",
"강간",
"개새끼",
"개자식",
"개좆",
"개차반",
"거유",
"계집년",
"고자",
"근친",
"노모",
"니기미",
"뒤질래",
"딸딸이",
"때씹",
"또라이",
"뙤놈",
"로리타",
"망가",
"몰카",
"미친",
"미친새끼",
"바바리맨",
"변태",
"병신",
"보지",
"불알",
"빠구리",
"사까시",
"섹스",
"스와핑",
"쌍놈",
"씨발",
"씨발놈",
"씨팔",
"씹",
"씹물",
"씹빨",
"씹새끼",
"씹알",
"씹창",
"씹팔",
"암캐",
"애자",
"야동",
"야사",
"야애니",
"엄창",
"에로",
"염병",
"옘병",
"유모",
"육갑",
"은꼴",
"자위",
"자지",
"잡년",
"종간나",
"좆",
"좆만",
"죽일년",
"쥐좆",
"직촬",
"짱깨",
"쪽바리",
"창녀",
"포르노",
"하드코어",
"호로",
"화냥년",
"후레아들",
"후장",
"희쭈그리",
"aardappels afgieteng",
"achter het raam zitten",
"afberen",
"aflebberen",
"afrossen",
"afrukken",
"aftrekken",
"afwerkplaats",
"afzeiken",
"afzuigen",
"anderhalve man en een paardekop",
"anita",
"asbak",
"aso",
"bagger schijten",
"balen",
"bedonderen",
"befborstelg",
"beffen",
"bekken",
"belazeren",
"besodemieterd zijn",
"besodemieteren",
"beurt",
"boemelen",
"boerelul",
"boerenpummelg",
"bokkelul",
"botergeil",
"broekhoesten",
"brugpieperg",
"buffelen",
"buiten de pot piesen",
"da's kloten van de bok",
"de ballen",
"de hoer spelen",
"de hond uitlaten",
"de koffer induiken",
"delg",
"de pijp aan maarten geven",
"de pijp uitgaan",
"dombo",
"draaikontg",
"driehoog achter wonen",
"drolg",
"drooggeiler",
"droogkloot",
"een beurt geven",
"een nummertje maken",
"een wip maken",
"eikel",
"engerd",
"flamoes",
"flikken",
"flikker",
"gadverdamme",
"galbak",
"gat",
"gedoogzone",
"geilneef",
"gesodemieter",
"godverdomme",
"graftak",
"gras maaien",
"gratenkutg",
"greppeldel",
"griet",
"hoempert",
"hoer",
"hoerenbuurt",
"hoerenloper",
"hoerig",
"hol",
"hufter",
"huisdealer",
"johny",
"kanen",
"kettingzeugg",
"klaarkomen",
"klerebeer",
"klojo",
"klooien",
"klootjesvolk",
"klootoog",
"klootzak",
"kloten",
"knor",
"kontg",
"kontneuken",
"krentekakker",
"kut",
"kuttelikkertje",
"kwakkieg",
"liefdesgrot",
"lul",
"lul-de-behanger",
"lulhannes",
"mafketel",
"matennaaierg",
"matje",
"mof",
"mutsg",
"naaien",
"naakt",
"neuken",
"neukstier",
"nicht",
"oetlul",
"opgeilen",
"opkankeren",
"oprotten",
"opsodemieteren",
"op z'n hondjes",
"op z'n sodemieter geven",
"opzouten",
"ouwehoer",
"ouwehoeren",
"ouwe rukker",
"paal",
"paardelul",
"palen",
"penozeg",
"piesen",
"pijpbekkieg",
"pijpen",
"pleurislaaier",
"poep",
"poepen",
"poot",
"portiekslet",
"pot",
"potverdorie",
"publiciteitsgeil",
"raaskallen",
"reet",
"reetridder",
"reet trappen, voor zijn",
"remsporeng",
"reutelen",
"rothoer",
"rotzak",
"rukhond",
"rukken",
"schatje",
"schijt",
"schijten",
"schoft",
"schuinsmarcheerder",
"slempen",
"sletg",
"sletterig",
"slik mijn zaad",
"snolg",
"spuiten",
"standje",
"standje-69g",
"stoephoer",
"stootje",
"strontg",
"sufferdg",
"tapijtnek",
"teefg",
"temeier",
"teringlijer",
"toeter",
"tongzoeng",
"triootjeg",
"trottoir prostituée",
"trottoirteef",
"vergallen",
"verkloten",
"verneuken",
"viespeuk",
"vingeren",
"vleesroos",
"voor jan lul",
"voor jan-met-de-korte-achternaam",
"watje",
"welzijnsmafia",
"wijf",
"wippen",
"wuftje",
"zaadje",
"zakkenwasser",
"zeiken",
"zeiker",
"zuigen",
"zuiplap",
"drittsekk",
"faen i helvete",
"fitte",
"jævla",
"kuk",
"kukene",
"kuker",
"pikk",
"sotrør",
"ståpikk",
"ståpikkene",
"ståpikker",
"burdel",
"burdelmama",
"chujnia",
"ciota",
"cipa",
"cyc",
"dmuchać",
"do kurwy nędzy",
"dupa",
"dupek",
"duperele",
"dziwka",
"fiut",
"gówno",
"gówno prawda",
"huj",
"jajco",
"jajeczko",
"jajko",
"jajo",
"ja pierdolę",
"jebać",
"jebany",
"kurwa",
"kurwy",
"kutafon",
"kutas",
"lizać pałę",
"obciągać chuja",
"obciągać fiuta",
"obciągać loda",
"pieprzyć",
"pierdolec",
"pierdolić",
"pierdolnięty",
"pierdoła",
"pierdzieć",
"pojeb",
"popierdolony",
"robic loda",
"ruchać",
"rzygać",
"skurwysyn",
"sraczka",
"srać",
"suka",
"syf",
"wkurwiać",
"zajebisty",
"aborto",
"amador",
"aranha",
"ariano",
"balalao",
"bicha",
"biscate",
"bissexual",
"boceta",
"bosta",
"braulio de borracha",
"bumbum",
"burro",
"cabrao",
"cacete",
"cagar",
"camisinha",
"caralho",
"cerveja",
"chochota",
"chupar",
"cocaína",
"colhoes",
"comer",
"cona",
"consolo",
"corno",
"cu",
"dar o rabo",
"dum raio",
"esporra",
"fecal",
"filho da puta",
"foda",
"foda-se",
"foder",
"frango assado",
"gozar",
"grelho",
"heterosexual",
"homem gay",
"homoerótico",
"homosexual",
"inferno",
"lésbica",
"lolita",
"mama",
"paneleiro",
"passar um cheque",
"pau",
"peidar",
"pinto",
"porra",
"puta que pariu",
"puta que te pariu",
"queca",
"sacanagem",
"saco",
"torneira",
"transar",
"vai-te foder",
"vai tomar no cu",
"veado",
"vibrador",
"xana",
"xochota",
"bychara",
"byk",
"chernozhopyi",
"dolboy'eb",
"ebalnik",
"ebalo",
"ebalom sch'elkat",
"gol",
"mudack",
"opizdenet",
"osto'eblo",
"ostokhuitel'no",
"ot'ebis",
"otmudohat",
"otpizdit",
"otsosi",
"padlo",
"pedik",
"perdet",
"petuh",
"pidar gnoinyj",
"pizdato",
"pizdatyi",
"piz'det",
"pizdetc",
"pizdoi nakryt'sja",
"pizd'uk",
"piz`dyulina",
"podi ku'evo",
"poeben",
"po'imat' na konchik",
"po'iti posrat",
"po khuy",
"poluchit pizdy",
"pososi moyu konfetku",
"prissat",
"proebat",
"promudobl'adsksya pizdopro'ebina",
"propezdoloch",
"prosrat",
"raspeezdeyi",
"raspizdatyi",
"raz'yebuy",
"raz'yoba",
"s'ebat'sya",
"shalava",
"styervo",
"sukin syn",
"svodit posrat",
"svoloch",
"trakhat'sya",
"trimandoblydskiy pizdoproyob",
"ubl'yudok",
"uboy",
"u'ebitsche",
"vafl'a",
"vafli lovit",
"v pizdu",
"vyperdysh",
"vzdrochennyi",
"yeb vas",
"za'ebat",
"zaebis",
"zalupa",
"zalupat",
"zasranetc",
"zassat",
"zlo'ebuchy",
"бардак",
"бздёнок",
"блядки",
"блядовать",
"блядство",
"блядь",
"бугор",
"во пизду",
"встать раком",
"выёбываться",
"гандон",
"говно",
"говнюк",
"голый",
"дать пизды",
"дерьмо",
"дрочить",
"другой дразнится",
"ёбарь",
"ебать",
"ебать-копать",
"ебло",
"ебнуть",
"ёб твою мать",
"жопа",
"жополиз",
"играть на кожаной флейте",
"измудохать",
"каждый дрочит как он хочет",
"какая разница",
"как два пальца обоссать",
"курите мою трубку",
"лысого в кулаке гонять",
"малофя",
"манда",
"мандавошка",
"мент",
"муда",
"мудило",
"мудозмон",
"наебать",
"наебениться",
"наебнуться",
"на фиг",
"на хуй",
"на хую вертеть",
"на хуя",
"нахуячиться",
"невебенный",
"не ебет",
"ни за хуй собачу",
"ни хуя",
"обнаженный",
"обоссаться можно",
"один ебётся",
"опесдол",
"офигеть",
"охуеть",
"охуйтельно",
"половое сношение",
"секс",
"сиски",
"спиздить",
"срать",
"ссать",
"траxать",
"ты мне ваньку не валяй",
"фига",
"хапать",
"хер с ней",
"хер с ним",
"хохол",
"хрен",
"хуёво",
"хуёвый",
"хуем груши околачивать",
"хуеплет",
"хуило",
"хуиней страдать",
"хуиня",
"хуй",
"хуйнуть",
"хуй пинать",
"arsle",
"brutta",
"discofitta",
"dra åt helvete",
"fan",
"fitta",
"fittig",
"för helvete",
"helvete",
"hård",
"jävlar",
"knulla",
"kuksås",
"kötthuvud",
"köttnacke",
"moona",
"moonade",
"moonar",
"moonat",
"mutta",
"olla",
"pippa",
"pitt",
"prutt",
"pök",
"runka",
"röv",
"rövhål",
"rövknulla",
"satan",
"skita",
"skit ner dig",
"skäggbiff",
"snedfitta",
"snefitta",
"stake",
"subba",
"sås",
"sätta på",
"tusan",
"กระดอ",
"กระเด้า",
"กระหรี่",
"กะปิ",
"กู",
"ขี้",
"ควย",
"จิ๋ม",
"จู๋",
"เจ๊ก",
"เจี๊ยว",
"ดอกทอง",
"ตอแหล",
"ตูด",
"น้ําแตก",
"มึง",
"แม่ง",
"เย็ด",
"รูตูด",
"ล้างตู้เย็น",
"ส้นตีน",
"สัด",
"เสือก",
"หญิงชาติชั่ว",
"หลั่ง",
"ห่า",
"หํา",
"หี",
"เหี้ย",
"อมนกเขา",
"ไอ้ควาย",
"ghuy'cha'",
"QI'yaH",
"Qu'vatlh",
"am",
"amcığa",
"amcığı",
"amcığın",
"amcık",
"amcıklar",
"amcıklara",
"amcıklarda",
"amcıklardan",
"amcıkları",
"amcıkların",
"amcıkta",
"amcıktan",
"amı",
"amlar",
"çingene",
"Çingenede",
"Çingeneden",
"Çingeneler",
"Çingenelerde",
"Çingenelerden",
"Çingenelere",
"Çingeneleri",
"Çingenelerin",
"Çingenenin",
"Çingeneye",
"Çingeneyi",
"göt",
"göte",
"götler",
"götlerde",
"götlerden",
"götlere",
"götleri",
"götlerin",
"götte",
"götten",
"götü",
"götün",
"götveren",
"götverende",
"götverenden",
"götverene",
"götvereni",
"götverenin",
"götverenler",
"götverenlerde",
"götverenlerden",
"götverenlere",
"götverenleri",
"götverenlerin",
"kaltağa",
"kaltağı",
"kaltağın",
"kaltak",
"kaltaklar",
"kaltaklara",
"kaltaklarda",
"kaltaklardan",
"kaltakları",
"kaltakların",
"kaltakta",
"kaltaktan",
"orospu",
"orospuda",
"orospudan",
"orospular",
"orospulara",
"orospularda",
"orospulardan",
"orospuları",
"orospuların",
"orospunun",
"orospuya",
"orospuyu",
"otuz birci",
"otuz bircide",
"otuz birciden",
"otuz birciler",
"otuz bircilerde",
"otuz bircilerden",
"otuz bircilere",
"otuz bircileri",
"otuz bircilerin",
"otuz bircinin",
"otuz birciye",
"otuz birciyi",
"saksocu",
"saksocuda",
"saksocudan",
"saksocular",
"saksoculara",
"saksocularda",
"saksoculardan",
"saksocuları",
"saksocuların",
"saksocunun",
"saksocuya",
"saksocuyu",
"sıçmak",
"sik",
"sike",
"siker sikmez",
"siki",
"sikilir sikilmez",
"sikin",
"sikler",
"siklerde",
"siklerden",
"siklere",
"sikleri",
"siklerin",
"sikmek",
"sikmemek",
"sikte",
"sikten",
"siktir",
"siktirir siktirmez",
"taşağa",
"taşağı",
"taşağın",
"taşak",
"taşaklar",
"taşaklara",
"taşaklarda",
"taşaklardan",
"taşakları",
"taşakların",
"taşakta",
"taşaktan",
"yarağa",
"yarağı",
"yarağın",
"yarak",
"yaraklar",
"yaraklara",
"yaraklarda",
"yaraklardan",
"yarakları",
"yarakların",
"yarakta",
"yaraktan",
"13.",
"13点",
"三级片",
"下三烂",
"下贱",
"个老子的",
"九游",
"乳",
"乳交",
"乳头",
"乳房",
"乳波臀浪",
"交配",
"仆街",
"他奶奶",
"他奶奶的",
"他奶娘的",
"他妈",
"他妈ㄉ王八蛋",
"他妈地",
"他妈的",
"他娘",
"他马的",
"你个傻比",
"你他马的",
"你全家",
"你奶奶的",
"你她马的",
"你妈",
"你妈的",
"你娘",
"你娘卡好",
"你娘咧",
"你它妈的",
"你它马的",
"你是鸡",
"你是鸭",
"你马的",
"做爱",
"傻比",
"傻逼",
"册那",
"军妓",
"几八",
"几叭",
"几巴",
"几芭",
"刚度",
"刚瘪三",
"包皮",
"十三点",
"卖B",
"卖比",
"卖淫",
"卵",
"卵子",
"双峰微颤",
"口交",
"口肯",
"叫床",
"吃屎",
"后庭",
"吹箫",
"塞你公",
"塞你娘",
"塞你母",
"塞你爸",
"塞你老师",
"塞你老母",
"处女",
"外阴",
"大卵子",
"大卵泡",
"大鸡巴",
"奶",
"奶奶的熊",
"奶子",
"奸",
"奸你",
"她妈地",
"她妈的",
"她马的",
"妈B",
"妈个B",
"妈个比",
"妈个老比",
"妈妈的",
"妈比",
"妈的",
"妈的B",
"妈逼",
"妓",
"妓女",
"妓院",
"妳她妈的",
"妳妈的",
"妳娘的",
"妳老母的",
"妳马的",
"姘头",
"姣西",
"姦",
"娘个比",
"娘的",
"婊子",
"婊子养的",
"嫖娼",
"嫖客",
"它妈地",
"它妈的",
"密洞",
"射你",
"小乳头",
"小卵子",
"小卵泡",
"小瘪三",
"小肉粒",
"小骚比",
"小骚货",
"小鸡巴",
"小鸡鸡",
"屁眼",
"屁股",
"屄",
"屌",
"干x娘",
"干七八",
"干你",
"干你妈",
"干你娘",
"干你老母",
"干你良",
"干妳妈",
"干妳娘",
"干妳老母",
"干妳马",
"干您娘",
"干机掰",
"干死CS",
"干死GM",
"干死你",
"干死客服",
"幹",
"强奸",
"强奸你",
"性",
"性器",
"性无能",
"性爱",
"情色",
"想上你",
"懆您妈",
"懆您娘",
"懒8",
"懒八",
"懒叫",
"懒教",
"成人",
"我操你祖宗十八代",
"扒光",
"打炮",
"打飞机",
"抽插",
"招妓",
"插你",
"插死你",
"撒尿",
"操你",
"操你全家",
"操你奶奶",
"操你妈",
"操你娘",
"操你祖宗",
"操你老妈",
"操你老母",
"操妳",
"操妳全家",
"操妳妈",
"操妳娘",
"操妳祖宗",
"操机掰",
"操比",
"操逼",
"放荡",
"日他娘",
"日你",
"日你妈",
"日你老娘",
"日你老母",
"日批",
"月经",
"机八",
"机巴",
"机机歪歪",
"杂种",
"浪叫",
"淫",
"淫妇",
"淫棍",
"淫水",
"淫秽",
"淫荡",
"淫西",
"湿透的内裤",
"激情",
"灨你娘",
"烂货",
"烂逼",
"爛",
"狗屁",
"狗日",
"狗狼养的",
"玉杵",
"王八蛋",
"瓜娃子",
"瓜婆娘",
"瓜批",
"瘪三",
"白烂",
"白痴",
"白癡",
"祖宗",
"私服",
"笨蛋",
"精子",
"老二",
"老味",
"老母",
"老瘪三",
"老骚比",
"老骚货",
"肉壁",
"肉棍子",
"肉棒",
"肉缝",
"肏",
"肛交",
"肥西",
"色情",
"花柳",
"荡妇",
"賤",
"贝肉",
"贱B",
"贱人",
"贱货",
"贼你妈",
"赛你老母",
"赛妳阿母",
"赣您娘",
"轮奸",
"迷药",
"逼",
"逼样",
"野鸡",
"阳具",
"阳萎",
"阴唇",
"阴户",
"阴核",
"阴毛",
"阴茎",
"阴道",
"阴部",
"雞巴",
"靠北",
"靠母",
"靠爸",
"靠背",
"靠腰",
"驶你公",
"驶你娘",
"驶你母",
"驶你爸",
"驶你老师",
"驶你老母",
"骚比",
"骚货",
"骚逼",
"鬼公",
"鸡8",
"鸡八",
"鸡叭",
"鸡吧",
"鸡奸",
"鸡巴",
"鸡芭",
"鸡鸡",
"龟儿子",
"龟头",


    // Add more words as needed
];

// Create regex pattern for whole words only, including variations with special characters
const profanityPattern = new RegExp(
    `\\b(${profanityList.join('|').replace(/[aeiou]/g, '[a@3e1i!0ou]')}|${
        profanityList.map(word => 
            word.split('').join('[^a-zA-Z]*')
        ).join('|')
    })\\b`, 
    'gi'
);

export const filterProfanity = (text) => {
    if (!text) return text;
    
    // Replace profanity with asterisks
    return text.replace(profanityPattern, match => 
        '*'.repeat(match.length)
    );
};

// Check if text contains profanity
export const containsProfanity = (text) => {
    if (!text) return false;
    return profanityPattern.test(text);
}; 