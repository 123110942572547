import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Paper,
    IconButton,
    Alert,
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { announcements } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import AddEditAnnouncementModal from '../components/announcements/AddEditAnnouncementModal';
import DeleteConfirmDialog from '../components/common/DeleteConfirmDialog';
import ReplySection from '../components/announcements/ReplySection';

function Announcements() {
    const { isAdmin } = useAuth();
    const queryClient = useQueryClient();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [announcementToDelete, setAnnouncementToDelete] = useState(null);

    const { data: announcementsData = [], isLoading, error } = useQuery(
        'announcements',
        () => announcements.getAll().then(res => res.data)
    );

    const deleteMutation = useMutation(
        (id) => announcements.delete(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('announcements');
                setDeleteDialogOpen(false);
                setAnnouncementToDelete(null);
            }
        }
    );

    if (isLoading) {
        return <Alert severity="info">Loading announcements...</Alert>;
    }

    if (error) {
        return <Alert severity="error">Error loading announcements: {error.message}</Alert>;
    }

    return (
        <Box>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4">Announcements</Typography>
                {isAdmin() && (
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setSelectedAnnouncement(null);
                            setDialogOpen(true);
                        }}
                    >
                        New Announcement
                    </Button>
                )}
            </Box>

            {announcementsData.map((announcement) => (
                <Paper key={announcement.id} sx={{ mb: 2, p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Box>
                            <Typography variant="h6">{announcement.title}</Typography>
                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                Posted by {announcement.author} on {new Date(announcement.created_at).toLocaleString()}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                {announcement.body}
                            </Typography>
                        </Box>
                        {isAdmin() && (
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        setSelectedAnnouncement(announcement);
                                        setDialogOpen(true);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        setAnnouncementToDelete(announcement);
                                        setDeleteDialogOpen(true);
                                    }}
                                    color="error"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                    <ReplySection
                        announcementId={announcement.id}
                        replies={announcement.replies || []}
                    />
                </Paper>
            ))}

            <AddEditAnnouncementModal
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                    setSelectedAnnouncement(null);
                }}
                announcement={selectedAnnouncement}
            />

            <DeleteConfirmDialog
                open={deleteDialogOpen}
                onClose={() => {
                    setDeleteDialogOpen(false);
                    setAnnouncementToDelete(null);
                }}
                onConfirm={() => {
                    if (announcementToDelete) {
                        deleteMutation.mutate(announcementToDelete.id);
                    }
                }}
                title="Delete Announcement"
                content="Are you sure you want to delete this announcement? This action cannot be undone."
            />
        </Box>
    );
}

export default Announcements;