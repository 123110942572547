import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    TextField,
    Alert
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { orders } from '../../services/api';

function OrderDialog({ open, onClose, items }) {
    const [quantities, setQuantities] = useState({});
    const [error, setError] = useState('');
    const queryClient = useQueryClient();

    const orderMutation = useMutation(
        (orderData) => Promise.all(orderData.map(data => orders.create(data))),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('orders');
                queryClient.invalidateQueries('inventory');
                onClose();
            },
            onError: (error) => {
                setError(error.response?.data?.message || 'Failed to create orders');
            }
        }
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        const orderData = items
            .filter(item => quantities[item.id] && quantities[item.id] > 0)
            .map(item => ({
                item_id: item.id,
                quantity: parseInt(quantities[item.id], 10)
            }));

        if (orderData.length === 0) {
            setError('Please enter at least one order quantity');
            return;
        }

        orderMutation.mutate(orderData);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Place Orders</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <List>
                        {items.map(item => (
                            <ListItem key={item.id}>
                                <ListItemText 
                                    primary={item.name}
                                    secondary={`Current quantity: ${item.quantity}`}
                                />
                                <TextField
                                    label="Order Quantity"
                                    type="number"
                                    size="small"
                                    value={quantities[item.id] || ''}
                                    onChange={(e) => setQuantities(prev => ({
                                        ...prev,
                                        [item.id]: e.target.value
                                    }))}
                                    inputProps={{ min: 1 }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        disabled={orderMutation.isLoading}
                    >
                        {orderMutation.isLoading ? 'Placing Orders...' : 'Place Orders'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default OrderDialog; 