import React from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    Alert,
    CircularProgress
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { orders } from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';

function PendingOrders() {
    const { isAdmin } = useAuth();
    const queryClient = useQueryClient();

    const { data: pendingOrders = [], isLoading, error } = useQuery(
        'pending-orders',
        orders.getPending,
        { 
            refetchInterval: 5000,
            select: (data) => Array.isArray(data) ? data : []
        }
    );

    const receiveMutation = useMutation(
        (orderId) => orders.receive(orderId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('pending-orders');
                queryClient.invalidateQueries('inventory');
            }
        }
    );

    if (!isAdmin()) return null;
    
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">Error loading orders: {error.message}</Alert>;
    }

    return (
        <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
                Pending Orders
            </Typography>
            {pendingOrders.length > 0 ? (
                pendingOrders.map(order => (
                    <Paper key={order.id} sx={{ p: 2, mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography variant="subtitle1">
                                    {order.item_name} - Quantity: {order.quantity}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Ordered: {new Date(order.created_at).toLocaleString()}
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() => receiveMutation.mutate(order.id)}
                                disabled={receiveMutation.isLoading}
                            >
                                Mark as Received
                            </Button>
                        </Box>
                    </Paper>
                ))
            ) : (
                <Alert severity="info">No pending orders</Alert>
            )}
        </Box>
    );
}

export default PendingOrders; 